import React, { FC } from 'react'
import styled from 'styled-components'

const StyContainer = styled.div`
  padding: 0 1rem;
  height: 50px;
  font-size: 1.0rem;
  font-weight: 600;
  letter-spacing: 0.15rem;
  
  background-color: rgb(67, 74, 90);
  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);
  
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyMiPnetLogo = styled.img`
  width: 50px;
  box-shadow: none;
`
const StyMiPnetText = styled.div`
  margin-left: 5px;

  @media only screen and (max-width: 600px) {
    margin-left: unset;
    visibility: hidden;
    width: 0;
  }
`

const StyLeft = styled.a`
  color: rgb(255, 255, 255);
  
  display: flex;
  align-items: center;

  width: 100px;

  @media only screen and (max-width: 600px) {
    width: unset;
  }
`

const StyCenter = styled.a`
  color: rgb(255, 255, 255);
`

const StyRight = styled.a`
  color: rgb(255, 255, 255);
  width: 100px;
  text-align: end;

  @media only screen and (max-width: 600px) {
    width: unset;
  }
`

const Header: FC<{}> = () => {
  return (
    <StyContainer>
      <StyLeft href="https://mipnet.de">
        <StyMiPnetLogo src="../mipnet-logo.png" alt="Logo" />
        <StyMiPnetText>MiPnet</StyMiPnetText>
      </StyLeft>
      <StyCenter href="../">Projects Overview</StyCenter>
      <StyRight href="../about">
        About
      </StyRight>
    </StyContainer>
  )
}

export default Header
