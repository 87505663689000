import React, { FC } from 'react'
import styled from 'styled-components'

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0 1rem;
`

const FlexItem = styled.div`
  font-size: small;
  color: rgb(100, 100, 100);

  @media only screen and (max-width: 600px) {
    font-size: x-small;
  }
`

const Footer: FC<{}> = () => (
  <FlexContainer>
    <FlexItem>
      This page does not save or use your personal data in any form.
    </FlexItem>
    <FlexItem style={{ marginLeft: '1rem' }}>
      proudly made without cookies
    </FlexItem>
  </FlexContainer>
)

export default Footer
